<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="options.y === 'bottom'"
    :color="options.color"
    :left="options.x === 'left'"
    :multi-line="options.mode === 'multi-line'"
    :right="options.x === 'right'"
    :timeout="options.timeout"
    :top="options.y === 'top'"
    :vertical="options.mode === 'vertical'"
  >
    <span v-html="text"></span>
    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click.native="close"
      >
        {{$t('base.close')}}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { defineComponent, toRefs, reactive } from "@vue/composition-api";
export default defineComponent({
  name: "WeeToast",
  setup() {
    const state = reactive({
      snackbar: false,
      text: null,
      options: {
        mode: "multi-line", //vertical, multi-line
        color: null,
        x: null, // left, right, center
        y: "top", //top, bottom
        timeout: 3000
      }
    });

    const toast = (text, options) => {
      state.snackbar = true;
      state.text = text;
      state.options = Object.assign(state.options, options);
    };
    const close = () => {
      state.snackbar = false;
    };

    return {
      ...toRefs(state),
      toast,
      close
    };
  }
});
</script>
